import { render, staticRenderFns } from "./ReviewCart.vue?vue&type=template&id=5bd3f939&scoped=true"
import script from "./ReviewCart.vue?vue&type=script&lang=js"
export * from "./ReviewCart.vue?vue&type=script&lang=js"
import style0 from "./ReviewCart.vue?vue&type=style&index=0&id=5bd3f939&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd3f939",
  null
  
)

export default component.exports